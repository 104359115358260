body {
  margin: 0;
  font-family: 'Source Sans Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:  #212721;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.bg-usaid {
  background-color: #CFCDC9;
}*/

.custom-navbar {
  display: flex;
  align-items: center;
}

.language-select {
  background-color: #ffffff;
}

.logo {
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 5px;
}

.accent-text {
  color: #002F6C;
  font-family:  "Gill Sans", sans-serif;
  padding-left: 0px;
}

.btn-primary {
  color:  #fff;
  background-color: #205493;
  border-color: #205493;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color:  #FFF;
  text-decoration: underline;
  transition: color .15s;
}

.accordion-button {
  color: #205493;
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  color: #205493;
  background-color: #A7C6ED;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.no-dropdown-toggle::after {
    content: none;
}

.nav {
  background-color: #BA0C2F;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #FFF;
    text-decoration: none;
    background-color: #BA0C2F;
    border-color: #BA0C2F; /* red */
    font-family:  "Gill Sans Bold", sans-serif;
    font-weight:  bold;
    font-size: 12px;
    border-bottom: none;
}

.nav-tabs .nav-link:hover{
    color: #A7C6ED;
    font-family:  "Gill Sans Bold", sans-serif;
    font-weight:  bold;
    font-size: 12px;
    border-color: #A7C6ED;
}

.nav-tabs .nav-link {
  font-family:  "Gill Sans Bold", sans-serif;
  font-weight:  bold;
  font-size: 12px;
  /*border-color: #205493;*/
}

.btn-outline-instructions {
  border-color: #002F6C;
}
